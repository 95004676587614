import 'gsap'

import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit, AfterViewInit, ViewEncapsulation, HostListener } from '@angular/core';

import _ from 'lodash';

import { WindowRef, ConfigurationService, UserService } from '@shared/services';
import { MetaService } from '@ngx-meta/core';

import { User } from '@shared/data'
import { first } from 'rxjs/operators';

window['fbAsyncInit'] = function() {
  Parse.FacebookUtils.init({ // this line replaces FB.init({
    appId      : '178601089344748', // Facebook App ID
    cookie     : true,  // enable cookies to allow Parse to access the session
    xfbml      : true,  // initialize Facebook social plugins on the page
    version    : 'v3.2' // point to the latest Facebook Graph API version
  });
};

let origin = window.location.origin;

if (!origin) {
  origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

let isDev = (window.location.hostname === 'localhost');

const localhostServerUrl = 'http://localhost:8082/1';

const SERVER_URL = isDev ? localhostServerUrl : origin + '/1';
// const SERVER_URL = 'https://tem.dev.coolbeans.studio/1';

window['SERVER_URL'] = SERVER_URL

const APPLICATION_ID = 'WeAAFnQDux2AN87V22dJ2RgRr3axu5xfu2bQ4UKW';
const JAVASCRIPT_KEY = 'QaW2PpJhgXyjWEfJu46JgjWAbn3uffw6MRSg4ypL';
const FILE_KEY = 'WEwRPNus9BbEKVQYfV378TwrPPUcfpGCexuZPSYf';

const defaultTags = {
  'og:url' : window.location.href
}

@Component({
  moduleId: 'module.id',
  selector: 'app',
  styleUrls: ['app.component.scss'],
  templateUrl: 'app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router, 
    private windowRef: WindowRef,
    private configurationService: ConfigurationService,
    private metaService: MetaService) {
    Parse.serverURL = SERVER_URL;
    Parse.initialize(APPLICATION_ID, JAVASCRIPT_KEY);

    window['fbAsyncInit']();
  }
  
  public ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      if (_.includes(evt.url, '/profile') && !_.includes(evt.url, 'track-order')) {
        return
      }
 
      if (this.windowRef.content) {
        this.windowRef.content.scrollTop = 0;
      } else {
        window.scroll(0, 0);
      }
    });

    this.configurationService.common().pipe(first()).subscribe(
      config => {
        this.metaService.setTitle(config.value['Home\'s Meta Title'].value)
        defaultTags['description'] = config.op['Home\'s Meta Description']
        defaultTags['og:image:width'] = config.op['Meta Image Width']
        defaultTags['og:image:height'] = config.op['Meta Image Height']
        defaultTags['og:site_name'] = config.op['Home\'s Meta Title']
        defaultTags['twitter:title'] = config.op['Home\'s Meta Title']

        _.forEach(defaultTags, ((o, k) => {
          this.metaService.setTag(k, o)
        }))
      }
    )
  }

  public ngAfterViewInit() {
    this.checkWidth(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    this.checkWidth(event.target.innerWidth);
  }

  private checkWidth(width: number) {
    this.windowRef.isSmall = width > WindowRef.SMALL_WIDTH;
    this.windowRef.isMedium = width > WindowRef.MEDIUM_WIDTH;
    this.windowRef.isLarge = width > WindowRef.LARGE_WIDTH;
    this.windowRef.isExtraLarge = width > WindowRef.EXTRA_LARGE_WIDTH;
  }
}
