import { Collection } from './collection';

export class Filter {
  public static readonly COLLECTION_TYPE = 'collection';
  public static readonly CATEGORY_TYPE = 'category';
  public static readonly EVENT_CATEGORY_TYPE = 'eventCategory';
  public static readonly PRICE_TYPE = 'price';
  public static readonly VIEWS_TYPE = 'views';
  public static readonly ATTACHMENT_GROUP_TYPE = 'attachmentGroup';
  public static readonly BRAND_TYPE = 'brand';

  public type?: string;
  public sub?: boolean;
  public value?: any;
  public label?: string;

  constructor(type?: string, value?:any) {
    this.type = type;
    this.value = value;
  }
}
