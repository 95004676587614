import { ParseObservable } from './parse-observable';
import { Injectable } from '@angular/core';

import { Observable, Observer } from 'rxjs';

import { Category, Menu, Brand } from './../data';
import { CategoryQuery, MenuQuery } from './../queries';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { switchMap } from 'rxjs/operators';

interface CategoryWithChildren {
  rows: Menu[];
  brandMap: object;
}

@Injectable()
export class CategoryService {
  private _find: Observable<Category[]>;
  private _categories: Observable<CategoryWithChildren>;
  private _tree: Observable<Category[]>;
  private _firstMain: Observable<Category>;
  private _secondMain: Observable<Category>;
  private _thirdMain: Observable<Category>;

  constructor() {

  }

  // public get(id: string): Observable<Category> {
  //   return this.tree().flatMap(parents => {
  //     let category;

  //     parents.forEach(p => {
  //       if (p.id == id) {
  //         category = p;
  //       } else {
  //         let children = <Category[]> p['children'];

  //         if (children) {
  //           let find = children.find(c => c.id == id);

  //           if (find) {
  //             category = find;
  //           }
  //         }
  //       }
  //     })

  //     return Observable.of(category);
  //   })
  // }

  public categories() {
    if (!this._categories) {
      this._categories = fromPromise(Parse.Cloud.run('menu-find', { includeBrands: true })).pipe(switchMap(value => {
        return Observable.of(value).publishReplay(1).refCount()
      }))
    }
    return this._categories
  }

  // public find() {
  //   if (!this._find) {
  //     let query = new CategoryQuery()
  //         .include(Category.PARENT_KEY)
  //         .include(Category.IMAGE_KEY)
  //         .equalTo(Category.ENABLED_KEY, true)
  //         .ascending(Category.SORT_ORDER_KEY);

  //     this._find = query.rx().find().publishReplay(1).refCount();
  //   }
  //   return this._find;
  // }

  // public tree() {
  //   if (!this._tree) {
  //     this._tree = this.find().map(arr => {
  //       return arr.filter(c => !c.parent).map(c => {
  //         c['children'] = arr.filter(o => o.parent && o.parent.id === c.id);
  //         return c;
  //       });
  //     }).publishReplay(1).refCount();
  //   }
  //   return this._tree;
  // }

  // public firstMain() {
  //   if (!this._firstMain) {
  //     this._firstMain = this.tree().map(c => c[0]);
  //   }
  //   return this._firstMain;
  // }

  // public secondMain() {
  //   if (!this._secondMain) {
  //     this._secondMain = this.tree().map(c => c[1]);
  //   }
  //   return this._secondMain;
  // }

  // public thirdMain() {
  //   if (!this._thirdMain) {
  //     this._thirdMain = this.tree().map(c => c[2]);
  //   }
  //   return this._thirdMain;
  // }
}
