import { ParseQuery } from './parse-query';

import '../extensions/reg-exp+escape';

import { Menu } from '@shared/data';

export class MenuQuery extends ParseQuery<Menu> {
  constructor() {
    super(Menu);
  }
}