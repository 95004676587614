import { Injectable } from '@angular/core';

import { Observable, Observer, Subscriber } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';

import { Product, ProductVariant } from './../data';

declare var ga: any;

@Injectable()
export class AnalyticService {
  constructor() {

  }

  public reportVisitors() {
    return fromPromise(Parse.Cloud.run('analytics-report')).first().publishReplay(1).refCount()
  }

  public addToCart(product: Product, variant?: ProductVariant) {
    ga('ec:addProduct', {
      'id': product.id,
      'name': product.name,
      // 'category': product.category,
      // 'brand': product.brand,
      // 'variant': product.variant,
      // 'price': product.price,
      // 'quantity': product.qty
    });
    ga('ec:setAction', 'add');
    ga('send', 'event', 'UX', 'click', 'add to cart');     // Send data using an event.
  }

  public clickProduct(product: Product) {
    ga('ec:addProduct', {               // Provide product details in a productFieldObject.
      'id': 'P12345',                   // Product ID (string).
      'name': 'Android Warhol T-Shirt', // Product name (string).
      'category': 'Apparel',            // Product category (string).
      'brand': 'Google',                // Product brand (string).
      'variant': 'Black',               // Product variant (string).
      'position': 1,                    // Product position (number).
      'dimension1': 'Member'            // Custom dimension (string).
    });

    ga('ec:setAction', 'click', {       // click action.
      'list': 'Search Results'          // Product list (string).
    });
  }

  public viewProduct(product: Product, variant?: ProductVariant) {
    // The impression from a Related Products section.
    ga('ec:addImpression', {            // Provide product details in an impressionFieldObject.
      'id': 'P12345',                   // Product ID (string).
      'name': 'Android Warhol T-Shirt', // Product name (string).
      'category': 'Apparel/T-Shirts',   // Product category (string).
      'brand': 'Google',                // Product brand (string).
      'variant': 'Black',               // Product variant (string).
      'list': 'Related Products',       // Product list (string).
      'position': 1                     // Product position (number).
    });

    // The product being viewed.
    ga('ec:addProduct', {                 // Provide product details in an productFieldObject.
      'id': 'P67890',                     // Product ID (string).
      'name': 'YouTube Organic T-Shirt',  // Product name (string).
      'category': 'Apparel/T-Shirts',     // Product category (string).
      'brand': 'YouTube',                 // Product brand (string).
      'variant': 'gray',                  // Product variant (string).
      'position': 2                       // Product position (number).
    });

    ga('ec:setAction', 'detail');       // Detail action.
  }

  public checkout(step: number, product: Product, variant?: ProductVariant) {
    ga('ec:addProduct', {               // Provide product details in an productFieldObject.
      'id': 'P12345',                   // Product ID (string).
      'name': 'Android Warhol T-Shirt', // Product name (string).
      'category': 'Apparel',            // Product category (string).
      'brand': 'Google',                // Product brand (string).
      'variant': 'black',               // Product variant (string).
      'price': '29.20',                 // Product price (currency).
      'quantity': 1                     // Product quantity (number).
    });

    // Add the step number and additional info about the checkout to the action.
    ga('ec:setAction','checkout', {
        'step': 1,
        'option': 'Visa'
    });
  }

  public purchase(product: Product, variant?: ProductVariant) {
    ga('ec:addProduct', {               // Provide product details in an productFieldObject.
      'id': 'P12345',                   // Product ID (string).
      'name': 'Android Warhol T-Shirt', // Product name (string).
      'category': 'Apparel',            // Product category (string).
      'brand': 'Google',                // Product brand (string).
      'variant': 'black',               // Product variant (string).
      'price': '29.20',                 // Product price (currency).
      'coupon': 'APPARELSALE',          // Product coupon (string).
      'quantity': 1                     // Product quantity (number).
    });

    ga('ec:setAction', 'purchase', {          // Transaction details are provided in an actionFieldObject.
      'id': 'T12345',                         // (Required) Transaction id (string).
      'affiliation': 'Google Store - Online', // Affiliation (string).
      'revenue': '37.39',                     // Revenue (currency).
      'tax': '2.85',                          // Tax (currency).
      'shipping': '5.34',                     // Shipping (currency).
      'coupon': 'SUMMER2013'                  // Transaction coupon (string).
    });
  }

  public impressProducts(products: Product[]) {
    ga('ec:addImpression', {            // Provide product details in an impressionFieldObject.
      'id': 'P12345',                   // Product ID (string).
      'name': 'Android Warhol T-Shirt', // Product name (string).
      'category': 'Apparel/T-Shirts',   // Product category (string).
      'brand': 'Google',                // Product brand (string).
      'variant': 'Black',               // Product variant (string).
      'list': 'Search Results',         // Product list (string).
      'position': 1,                    // Product position (number).
      'dimension1': 'Member'            // Custom dimension (string).
    });
  }

  public impressPromotion() {
    ga('ec:addPromo', {               // Promo details provided in a promoFieldObject.
      'id': 'PROMO_1234',             // Promotion ID. Required (string).
      'name': 'Summer Sale',          // Promotion name (string).
      'creative': 'summer_banner2',   // Creative (string).
      'position': 'banner_slot1'      // Position  (string).
    });
  }

  public clickPromotion() {
    // Identify the promotion that was clicked.
    ga('ec:addPromo', {
      'id': 'PROMO_1234',
      'name': 'Summer Sale',
      'creative': 'summer_banner2',
      'position': 'banner_slot1'
    });

    // Send the promo_click action with an event.
    ga('ec:setAction', 'promo_click');
  }
}
