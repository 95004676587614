import * as _ from 'lodash';

import { FileRef } from './file-ref';
import { ParseObject } from './parse-object';

export class EventGalleryItem extends ParseObject {
  public static readonly CLASS_NAME = 'EventGalleryItem';
  public static readonly TITLE_KEY = 'title';
  public static readonly IMAGE_KEY = 'image';
  public static readonly ENABLED_KEY = 'enabled';
  public static readonly SORT_ORDER_KEY = 'sortOrder';

  constructor() {
    super(EventGalleryItem.CLASS_NAME);
    this.enabled = true;
  }

  public get enabled(): boolean {
    return this.get(EventGalleryItem.ENABLED_KEY);
  }

  public set enabled(disabled: boolean) {
    this.set(EventGalleryItem.ENABLED_KEY, disabled);
  }

  public get sortOrder(): number {
    return this.get(EventGalleryItem.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(EventGalleryItem.SORT_ORDER_KEY, sortOrder);
  }

  public get image(): FileRef {
    return this.get(EventGalleryItem.IMAGE_KEY);
  }

  public set image(image: FileRef) {
    this.set(EventGalleryItem.IMAGE_KEY, image);
  }

}

Parse.Object.registerSubclass(EventGalleryItem.CLASS_NAME, EventGalleryItem);
