import { ParseObject } from './parse-object';

import * as _ from 'lodash';

import { FileRef } from './file-ref';
import { Category } from './category';

export class Menu extends ParseObject {
  public static readonly CLASS_NAME = 'Menu';
  public static readonly BRAND_KEY = 'brand';
  public static readonly PARENT_KEY = 'parent';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly ENABLED_KEY = 'enabled';
  public static readonly NAME_KEY = 'name';
  public static readonly DESCRIPTION_KEY = 'desc';
  public static readonly IMAGE_KEY = 'image';
  public static readonly ICON_KEY = 'icon';

  constructor(id?: string) {
    super(Menu.CLASS_NAME);
    this.id = id;
  }

  public static create({ sortOrder, enabled = true, parent, brand, name}: Partial<Menu>) {
    return Object.assign(new Menu(), { enabled, parent, sortOrder, brand, name  })
  }

  public get brand(): Category {
    return this.get(Menu.BRAND_KEY);
  }

  public set brand(brand: Category) {
    this.set(Menu.BRAND_KEY, brand);
  }

  public get parent(): Menu {
    return this.get(Menu.PARENT_KEY);
  }

  public set parent(menu: Menu) {
    this.set(Menu.PARENT_KEY, menu);
  }

  public get sortOrder(): number {
    return this.get(Menu.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(Menu.SORT_ORDER_KEY, sortOrder);
  }

  public get enabled(): boolean {
    return this.get(Menu.ENABLED_KEY);
  }

  public set enabled(enabled: boolean) {
    this.set(Menu.ENABLED_KEY, enabled);
  }

  public get name(): string {
    return this.get(Menu.NAME_KEY);
  }

  public set name(name: string) {
    this.set(Menu.NAME_KEY, name);
  }

  public get image(): FileRef {
    return this.get(Menu.IMAGE_KEY);
  }

  public set image(image: FileRef) {
    this.set(Menu.IMAGE_KEY, image);
  }

  public get icon(): FileRef {
    return this.get(Menu.ICON_KEY);
  }

  public set icon(icon: FileRef) {
    this.set(Menu.ICON_KEY, icon);
  }

  public get thumbnailUrl() {
    return this.image ? this.image.localUrl : FileRef.NO_IMAGE_PATH;
  }

  public entries(): ParseObject[] {
    return _.compact([this.image, this]);
  }
}

Parse.Object.registerSubclass(Menu.CLASS_NAME, Menu);
