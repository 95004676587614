import { ParseObject } from './parse-object';

import * as _ from 'lodash';

import { FileRef } from './file-ref';
import { Category } from './category';

export class Brand extends ParseObject {
  public static readonly CLASS_NAME = 'Brand';
  public static readonly NAME_KEY = 'name';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly DESCRIPTION_KEY = 'desc';
  public static readonly IMAGE_KEY = 'image';
  public static readonly ENABLED_KEY = 'enabled';
  public static readonly CATEGORIES_KEY = 'categories';

  constructor() {
    super(Brand.CLASS_NAME);
  }

  public get categories(): Category[] {
    return this.get(Brand.CATEGORIES_KEY);
  }

  public set categories(categories: Category[]) {
    this.set(Brand.CATEGORIES_KEY, categories);
  }

  public get enabled(): boolean {
    return this.get(Brand.ENABLED_KEY);
  }

  public set enabled(enabled: boolean) {
    this.set(Brand.ENABLED_KEY, enabled);
  }

  public get sortOrder(): number {
    return this.get(Brand.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(Brand.SORT_ORDER_KEY, sortOrder);
  }

  public get name(): string {
    return this.get(Brand.NAME_KEY);
  }

  public set name(name: string) {
    this.set(Brand.NAME_KEY, name);
  }

  public get description(): string {
    return this.get(Brand.DESCRIPTION_KEY);
  }

  public set description(description: string) {
    this.set(Brand.DESCRIPTION_KEY, description);
  }

  public get image(): FileRef {
    return this.get(Brand.IMAGE_KEY);
  }

  public set image(image: FileRef) {
    this.set(Brand.IMAGE_KEY, image);
  }
}

Parse.Object.registerSubclass(Brand.CLASS_NAME, Brand);
